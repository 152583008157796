import React from "react"
import { Container } from "react-bootstrap"
import Icon from "../modules/icon"
import richTextRenderer from '../helper/richTextRenderer'
import Image from 'gatsby-image'

const GeneralContent = ({ title, content, image, textCentered, icon, addionalClass, handwritten, headlineType }) => (
  <section className={`component generalContent ${addionalClass}`}>
    <Container fluid className="color_invert">
      <div className="row justify-content-center">
          <div className={`col-12`}>
            {icon &&
              <Icon name={icon} width="30" height="50" />
            }
            {headlineType === 1 ?
              <h1 className={`h3 mb-3 text-center ${handwritten ? 'handwritten' : ''}`}>{title}</h1>
            :
              <h2 className={`h3 mb-3 text-center ${handwritten ? 'handwritten' : ''}`}>{title}</h2>
            }
            <div className={`contentColumn ${textCentered ? 'text-center' : ''}`}>
              {(image && image.fixed) &&
                <div className="text-center">
                  <Image fixed={image.fixed} />
                </div>
              }
              {(image && image.file) && <img src={image.file.url} alt="preview" />}
              {richTextRenderer(content)}

              {(image && image.fluid) &&
                <div className="row">
                  <div className="col-12 col-md-4">
                    <Image fluid={image.fluid} />
                  </div>
                </div>
              }
            </div>
          </div>
      </div>
    </Container>
  </section>
)

export default GeneralContent
